import React from 'react';
import logo from './logo.svg';
import '../App.css';
import Navbar from './Navbar';
// import SceneViewer from './SceneViewer';



function Landing() {
  return (
    <section className="px-2 bg-gray-900 py-32 md:px-0 font-mono" style={{backgroundColor: 'rgb(234,234,234)'}}>
      
      
  <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
    <div className="flex flex-wrap items-center sm:-mx-3">
      <div className="w-full md:w-1/2 md:px-3">
        <div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
          <h1 className="text-4xl font-extrabold tracking-tight text-gray-800 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
            <span className="block xl:inline">Create and share AI generated art on </span>
            <span className="block text-sky-500 xl:inline">iMessage.</span>
          </h1>
          <p className="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">Skip the copy and pasting. Skip the scattered screenshots. </p>
          <div className="relative flex flex-col sm:flex-row sm:space-x-4">
            <a href="#_" className="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-sky-500 rounded-md sm:mb-0 hover:bg-sky-600 sm:w-auto">
            View on the App Store
              <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
            </a>
            {/* <a href="#_" className="flex items-center px-6 py-3 text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600">
              Learn More
            </a> */}
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2">
        <div className="w-full h-auto">
            {/* <img src="https://images.unsplash.com/photo-1498049860654-af1a5c566876?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"></img> */}
            {/* <iframe width="640" height="360" frameBorder="0" src="https://www.shadertoy.com/embed/XsXGDH?gui=false&t=10&paused=false&muted=false" allowFullScreen></iframe> */}
            {/* <div className="sketchfab-embed-wrapper"> <iframe title="Robot Playground" frameBorder="0"  allowFullScreen  allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/59fc99d8dcb146f3a6c16dbbcc4680da/embed"> </iframe> </div> */}
            {/* <SceneViewer /> */}
            {/* <iframe height="300" style={{width: '100%'}} title="3D Robot with Spline.design @spline.design" src="https://codepen.io/igcorreia/embed/eYggwxW?default-tab=result">
  
</iframe> */}
      {/* <video width="320" height="240" autoPlay loop muted>
        <source src="../robo-clipped.mov" type="video/mov" />
      </video> */}
      {/* <img src='https://i.ibb.co/bW1GyMN/robo.png'></img> */}
      <div className='imgBox'>
                <img src='https://i.ibb.co/bW1GyMN/robo.png'></img>
              </div>
          </div>
      </div>
    </div>
  </div>
</section>


  );  
}

export default Landing;
