import React from 'react';
import logo from './logo.svg';
import '../App.css';

function Navbar() {
  return (
    <section className="w-full px-8 text-gray-100 font-mono" style={{backgroundColor: 'rgb(234 234 234)'}}>
    <div className="container flex flex-col flex-wrap items-center justify-between py-5 mx-auto md:flex-row max-w-7xl">
        <div className="relative flex flex-col md:flex-row">
            <a href="#_" className="flex items-center mb-5 font-medium text-gray-100 lg:w-auto lg:items-center lg:justify-center md:mb-0">
                <span className="mx-auto text-xl font-black leading-none text-gray-800 select-none">RoboDream<span className="text-sky-500">.</span></span>
            </a>
            <nav className="flex flex-wrap items-center mb-5 text-base md:mb-0 md:pl-8 md:ml-8 md:border-l md:border-gray-500">
                {/* <a href="#_" className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900">Home</a> */}
                {/* <a href="#_" className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900">Features</a> */}
                <a href="#inquiries" className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-500">Business Inquiries</a>
                {/* <a href="#_" className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900">Blog</a> */}
            </nav>
        </div>

        <div className="inline-flex items-center ml-5 space-x-6 lg:justify-end">
            {/* <a href="#" className="text-base font-medium leading-6 text-gray-600 whitespace-no-wrap transition duration-150 ease-in-out hover:text-gray-900">
                Sign in
            </a> */}
            {/* <a href="#" className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600">
                Download Now
            </a> */}
            <a href="#_" className="flex items-center px-6 py-3 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-900 hover:text-gray-600">
              Download Now
            </a>
        </div>
    </div>
</section>

  );  
}

export default Navbar;
