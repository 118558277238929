import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import Landing from "./components/Landing";
import InfoBits from "./components/InfoBits";
import Footer from "./components/Footer";
import dLanding from "./components/dLanding";

import { createBrowserRouter } from "react-router-dom";

function App() {
  return (
    <div>
      <Navbar />
      <Landing />
      <InfoBits />
      <Footer />
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  // Now just at /privacy.html
  // {
  //   path: "/privacy",
  // element: <PrivacyPolicy />,
  // },
]);

export default router;
